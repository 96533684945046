.cursive {
  font-family: "IM Fell English", serif;
  font-size: 2rem;
  line-height: 1.2;
}

.spooky {
  color: black;
  -webkit-text-fill-color: white;
  -webkit-text-stroke: 1px black;
  mix-blend-mode: multiply;
}

.set-heading {
  font-weight: bold;
  font-size: 1.4rem;
  color: var(--black);
}

li a:hover {
  color: var(--black);
}

.scrollable {
  overflow: auto;
}

.tab-content {
  height: 50vh;
  overflow-y: auto;
  overflow-x: clip;
}

.docs-nav {
  font-size: 1.728rem;
  color: var(--cream);
  font-family: "Libre Baskerville", sans-serif;
}

.flip-card {
  background-color: transparent;
  width: 300px;
  height: 200px;
  perspective: 500px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card.flipped .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #efefef;
  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: #efefef;
  color: black;
  transform: rotateY(180deg);
}

.big-centered-icon {
  font-size: 7.5rem;
  font-size: 8vmax;
  position: absolute;
  top: 0;
  opacity: 0.15;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  border: 0;
}
