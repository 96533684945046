@font-face {
  font-family: "IM Fell English";
  src: url("./fonts/IMFellEnglish-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "IM Fell English SC";
  src: url("./fonts/IMFellEnglishSC-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"), url("./fonts/Montserrat-Variable.woff2") format("woff2");
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat Italic"), local("Montserrat-Italic"),
    url("./fonts/Montserrat-Italic-Variable.woff2") format("woff2");
  font-style: italic;
}

@font-face {
  font-family: "Libre Baskerville";
  src: local("Libre Baskerville"), local("LibreBaskerville"), url("./fonts/LibreBaskerville.woff2") format("woff2");
}

:root {
  --gray: #a6a99d;
  --black: #150b00;
  --light-green: #c4d3ca;
  --green: #315c2b;
  --light-blue: #b3ceea;
  --blue: #0e68ab;
  --light-red: #eb9f82;
  --red: #5a1807;
  --cream: #f7dd72;
  --white: #f9faf4;

  --bs-dark-rgb: 25, 46, 57;
  --bg: rgb(var(--bs-dark-rgb));
}

.offset {
  margin-top: 12vh;
}

.text-cream {
  color: var(--cream);
}

hr {
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

nav a:hover {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Libre Baskerville", sans-serif;
}

h2 {
  font-size: 2.074rem;
}

h3 {
  font-size: 1.728rem;
}

h4 {
  font-size: 1.44rem;
}

h5 {
  font-size: 1.2rem;
}

.navbar-dark .navbar-brand {
  font-family: "IM Fell English SC", serif;
  color: var(--cream) !important;
}

.navbar-dark .navbar-brand:hover {
  color: var(--cream);
}

.navbar-dark .navbar-nav a.nav-link {
  color: var(--cream);
}

.navbar-dark .navbar-nav a.nav-link {
  color: var(--cream) !important;
}

.nav .nav-item .nav-link {
  color: var(--cream);
}

[role="tablist"] li.nav-item .nav-link.active {
  color: var(--bg);
}

.navbar-dark .nav-link:hover {
  color: var(--white);
}

.navbar-dark .navbar-toggler {
  color: var(--cream);
  border-color: var(--white);
  font-size: 1em;
}

.navbar-dark {
  background-color: #192e39;
}

.dropdown {
  list-style-type: none;
}
.dropdown-menu a.dropdown-item {
  background-color: var(--bg);
  color: var(--cream);
}

#header-nav .dropdown-menu a.dropdown-item:hover,
#header-nav .nav-link:hover {
  filter: brightness(0.8);
}

a {
  color: #004ba1;
}

/* get the dirty, 'noisy' background */
body {
  background: linear-gradient(90deg, #eed 0%, #ffe 10%, #ffe 90%, #eed 100%);
  font-family: "Montserrat", sans-serif;
}

/* Extra small devices (phones, 600px and down) */
@media only screen {
}

.navbar-dark .navbar-brand {
  font-size: 2rem;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 651px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 1000px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}

.navbar-toggler-icon {
  font-size: 2em;
}

.nav-tabs {
  font-size: 1.728rem;
  color: var(--cream);
  font-family: "Libre Baskerville", sans-serif;
}
